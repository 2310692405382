import { useContext } from "react";
import "./newslatter.sass";
import { Button,FloatingLabel,Form } from "react-bootstrap";
export function Newslatter() {

  return (
    <>
      <div className="news">
 
        <div className=" container">
  
            <div className="m-auto texto">
      
      <div>
      <h3 className="title">ASSINE NOSSA NEWSLETTER</h3>
      </div>


            <div>
            <Form>
            <FloatingLabel
        controlId="nome"
        label="Seu nome"
        className="mb-3"
      >
        <Form.Control type="text" placeholder="Seu nome" />
      </FloatingLabel>
      <FloatingLabel controlId="floatingInput"         label="Seu E-mail"
>
        <Form.Control type="email" placeholder="seuemaiL@email.com" />
      </FloatingLabel>

      <Form.Group className="my-3" controlId="formBasicCheckbox">
        <Form.Check type="checkbox" label=" Aceito receber e-mails com informações relevantes sobre Elcom." />
      </Form.Group>
      <div className="d-flex">
      <a
                className="link"
                target="_blankcode "
              >
             Cadastrar
             <i class="fa-solid fa-arrow-right"></i>
              </a>
      </div>

     
    </Form>
             
            </div>
            </div>
        
        </div>
      </div>
    </>
  );
}
