import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, useParams, Link } from "react-router-dom";
import {
  parseISO,
} from 'date-fns';
import {  formatToTimeZone } from 'date-fns-timezone';
import wp from "../../helpers/wp";
import { Header } from "../../template/header/header";
const CasesPaginaCustom = () => {

  const { slug } = useParams();
  const [newCorpo, setCorpo] = useState();

  const filter = (str) => {
    str = str.replace(/[ÀÁÂÃÄÅ]/g, "A");
    str = str.replace(/[àáâãäå]/g, "a");
    str = str.replace(/[ÈÉÊË]/g, "E");
    return str.replace(/[^a-z0-9]/gi, "");
  };




  useEffect(() => {
    const carregarPagina = () => {
      wp.get(`posts/?slug=${slug}`).then(res => {
        var postagem = [];
        res?.data.find(item => {
          var parsedDate = parseISO(item.date);
          console.log(item)
          const output = formatToTimeZone(parsedDate, 'DD/MM/YYYY HH:mm', { timeZone: 'America/Sao_Paulo' })
          const tratamento = {
            data: output,
            img: item.featured_media_src_url,
            titulo: item.title,
            tipo: item.tags,
            setor: item.categories,
            html: item.content,
          }
          postagem.push(tratamento)
        })
        setCorpo(postagem)
      });
    };
    carregarPagina();
  }, [slug]);

  return (
    <>

  
              {newCorpo && (
                <>
                    <Header titulo={newCorpo[0]?.titulo.rendered}  subtitulo={newCorpo[0]?.data} img={newCorpo[0]?.img}></Header>
                    <div className="container my-3 ">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="card">
                <div>
                  <div className="card-body pt-4">
                  
                    <p className="card-text">



                   

                    {   <div dangerouslySetInnerHTML={ { __html : newCorpo[0]?.html.rendered  }}/> }
                    </p>

                 {newCorpo.pdf && newCorpo.pdf.map((item) => (
                                <section
                                id="pdf-section"
                                className="d-flex flex-column align-items-center w-100"
                              >
                             {/* <ControlPanel url={item[0].url}></ControlPanel> */}
                              </section>
                 ))}
       

                    <Link className="btn btn-primary" to={"/noticias"}>
                      Voltar
                    </Link>
                  </div>
                </div>
                </div>
          </div>

         
        </div>
      </div>
             </> )}
    
    </>
  );
};
export default CasesPaginaCustom;
