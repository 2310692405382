import React, { useEffect, useLayoutEffect, useState } from "react";
import "./topo.sass";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link } from "react-router-dom";
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };}
export function Topo({escuro}) {
  const [windowvalue,setWindowDimensions] = useState(getWindowDimensions());
  const [fixed, SetFixed] = useState(false);
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [window.location]);

  useEffect(() => {
    const handleScroll = event => {
      SetFixed(window.scrollY > 150)
    };
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);


    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);

    };
  }, []);
  return (
    <>
      <Navbar  expand="lg"  fixed="top"  className={fixed ? 'topo background' : 'topo'}>
        <Container>
            <Link className="nav-brand" style={{width: '150px'}} to="/" preventScrollReset={true} >
              {escuro ? <img src="/logo-topo.png" className="logo"   alt="logo Grupo Elcom"/> :   <img src="/logo-topo.png"  alt="logo Grupo Elcom" className="logo" />}
            </Link>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${"lg"}`} />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${"lg"}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${"lg"}`}
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${"lg"}`}>
              <Link className="nav-brand" to="/" preventScrollReset={true} >
              <img src="./logo.png"  style={{width: '137px'}}  className="logo" alt="logo ibipay" />
            </Link>
       
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className=" flex-grow-1 pe-3">
    
               <Nav.Item> 
               <Link className="nav-link" to="/sobre" preventScrollReset={true} >
               Sobre Elcom
               </Link>
               </Nav.Item>
               <Nav.Item>
               <Link className="nav-link" to="/servicos" preventScrollReset={true} >
               Produtos
               </Link>
               </Nav.Item>
        
               <Nav.Item>
               <Link className="nav-link" to="/cases" preventScrollReset={true} >
               Cases
               </Link>

          
               </Nav.Item>
               <Nav.Item>
             
               </Nav.Item>
           
     
              </Nav>
              <Nav className="justify-content-end flex-grow-1 pe-3">
                <Nav.Item className="destaque"> 
                <Link className="nav-link" to="/fale-conosco" preventScrollReset={true} >
               Anúncie Aqui!
               </Link>
               </Nav.Item>
              </Nav>

            
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>

      {/* 

<div  className={fixed ? 'navbar fixed-top  navbar-expand-lg bg-body-tertiary topo' : 'navbar navbar-expand-lg bg-body-tertiary topo'}>
  <div className="container">
    <a className="navbar-brand" href="#"><img src="./logo.png" className='logo'/></a>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
    <ul className="navbar-nav m-auto mb-2 mb-lg-0">

        
        <li className="nav-item">
          <a className="nav-link" href="#">  Política de Privacidade</a>
        </li>
      </ul>
    
      <ul className="navbar-nav ml-auto mb-2 mb-lg-0">
        <li className="nav-item destaque">
          <a className="nav-link " href="#">Abra a sua conta</a>
        </li>
    
      </ul>
    
    </div>
  </div>
</div> */}
    </>
  );
}
