import React,{ memo } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Pagina } from '../../template/pagina/pagina';
import "./sobre.sass"
import "react-multi-carousel/lib/styles.css";
export default memo(function SobrePagina() {
return(<>
<>
<div className="sobre ">




<div className="quemsomos">


<Container>
<Row>
<Col sm className="order-mobile" >
        <img  src="grupoelcom.png" alt="dois parceiros de negocios trabalhando no escritorio" />
    </Col>
<Col sm className="flex p-4">
    <div className="m-auto">
    <h2>
    QUEM É O GRUPO ELCOM
</h2>

<p>
Comunicação eficaz é fundamental para o sucesso de uma empresa, independentemente do tamanho. Mas às vezes, atrair a atenção dos consumidores e transmitir sua mensagem de forma clara pode ser um desafio. É aí que entra o Grupo elcom, uma empresa nacional especializada em criar e implantar projetos de comunicação visual e mobiliários urbanos em escala gigantesca.
</p>
<p>
Com a sua expertise, o Grupo elcom adiciona beleza aos centros urbanos e transforma cenários, criando novos pontos de atração turística. A comunicação eficaz e a beleza urbana se unem para oferecer uma experiência única aos consumidores e destacar sua marca de uma forma nunca antes vista. 
</p>
<p>
Não perca mais tempo e oportunidades de crescimento por causa de uma má comunicação. Deixe o Grupo elcom ajudá-lo a transmitir sua mensagem com impacto e atrair a atenção dos consumidores. Juntos, podemos transformar a sua empresa em um verdadeiro destaque no mercado. 
</p>
<p>
ELCOM comunicação é uma empresa de mídia pioneira que está mudando a face da indústria Out of Home no Brasil. 
</p>
<p>
Aprimoramos os espaços públicos criando ambientes envolventes, ajudando anunciantes, proprietários, arrendatários, organizações comunitárias, conselhos locais e governos a alcançar públicos amplos e diversos.

</p>
<p>
Investimos pesadamente em tecnologia, somos pioneiros no uso de técnicas de dados sofisticadas para permitir que os clientes maximizem seus gastos com mídia por meio de uma segmentação de público precisa e inigualável.

</p>
    </div>

    </Col>
 
</Row>
</Container>



</div>




<div className="oohmidia">
<div className="container">
<div className="row py-4">
<div className="col-sm flex">
<img src="oohmidia.png"  style={{zIndex: '999'}} width={'100%'} className="mb-auto" />
</div>
<div className="col-sm flex">


<div className="m-auto">

<h2>
Por que OUT OF HOME (OOH)?
</h2>
<p>
OUT OF HOME conecta anunciantes com públicos no local, gerando impacto, reconhecimento e conversão.
OOH, ao contrário de muitos canais de publicidade, é ininterrupto, presente e totalmente disponível. É parte crucial do crescimento e relevância da marca, dada a presença física e a beleza na exposição! É o único meio de estabelecer destaque, lealdade e defesa da marca, deixando sua marca falar por si mesma.
 
</p>
<h3>
O formato imperdível:
</h3>
<p>
OOH não pode ser silenciado, bloqueado, desligado ou ignorado
</p>
<p>
É um canal verdadeiramente imperdível para as marcas se envolverem com o público
 Marca Segura
Em um mundo onde construir confiança para as marcas parece ficar mais difícil a cada ano, OOH é um dos meios mais confiáveis
Os anunciantes têm controle total do criativo e da localização, o que significa que não há veiculações indesejadas
OOH pode atender a uma ampla gama de objetivos em todo o funil de marketing
</p>




        </div>
</div>
</div>
</div>
</div>


<div className="mv" >
  <div className="fundo" style={{backgroundImage: `url('./billboards.jpeg')`}}></div>
<div className="container text-center">
<div className="row py-4 ">

<div className="m-auto ">
<h3>
        Missão 
</h3>
<p>
A mídia Out of Home é nossa força e a nossa paixão. 
Nossa missão é simples; para dar a você a exposição mais visível e direcionada na cidade e ajudar sua marca a comunicar sua mensagem da maneira mais eficaz e memorável.
</p>

  </div>

</div>

<div className="row py-4 ">

<div className="m-auto">

<h3>
Visão 
</h3>
<p>
Nossos meios estão todos estrategicamente localizados, e nossa visão é incorporar cada marca de forma impecável na paisagem natural da cidade. Acreditamos na publicidade com impacto, bom gosto e como a ponte entre você e seu cliente.
</p>

  </div>

</div>

<div className="row py-4 ">

<div className="m-auto">

<h3>
Valores 
</h3>
<p>
Impacto: Fornecer publicidade de alta qualidade e impacto para maximizar a eficácia das campanhas publicitárias.
Bom gosto: Garantir que a publicidade seja atraente e visualmente agradável, evitando conteúdo ofensivo ou sensível.
Direcionamento: Esforçar-se para direcionar a publicidade ao público certo, para que seja mais eficaz em alcançar as pessoas certas.
Integração na paisagem: Integrar a publicidade de forma impecável na paisagem natural da cidade para torná-la mais atraente e menos intrusiva.
</p>
  </div>

</div>
</div>
</div>
</div>


</>
</>);

});