import { Link } from "react-router-dom";
import  './cases.sass'
import wp from "../../../helpers/wp";
import { useEffect, useState } from "react";
import { parseISO } from "date-fns";
import { formatToTimeZone } from "date-fns-timezone";
import { Col, Row, Spinner } from "react-bootstrap";
export function Cases() {
  const [noticias, setNoticias] = useState([]);
  useEffect(() => {
    wp.get("posts?_embed").then(res => {
      var postagens = [];
      res.data.find(item => {
        console.log(item)
        var parsedDate = parseISO(item.date);
        const output = formatToTimeZone(parsedDate, 'DD/MM/YYYY HH:mm', { timeZone: 'America/Sao_Paulo' })
        const tratamento = {
          data: output,
          titulo: item.title,
          tipo: item.tags,
          url: item.slug,
          setor: item.categories,
          resumo: item.excerpt,
          img: item.featured_media_src_url
        }
        postagens.push(tratamento)
      })
      setNoticias(postagens)
    }
      
      
      )

  },[])
  return (
    <>
     <div className="cases">

<div className='fullscreen' id="trabalhos">
<div className="container">
<h3 className="title">CASES</h3>
<p className="paragraph">
Confira aqui projetos especiais feitos para uma audiência qualificada e exibidos em pontos estratégicos espalhados por todo o país.
</p>

{/* <div className="itens"> 
{noticias.slice(0,3).map((item, index) => (
  <div className="item"  data-aos="zoom-in-up"
  data-aos-easing="linear"
  data-aos-duration={index * 400}>
  <div className="img-efect"> 

<img src={item.img} />

</div>
<div className="label">
  <h3>{item.titulo}</h3>
  <p>{item.label}</p>
</div>
  </div> */}



<Row sm="3" className="g-5 justify-content-md-center itens" >

{noticias.length > 0 ? noticias.slice(0,3).map((item, index) => (

<Col 
className="item"
sm data-aos="zoom-in-up"
data-aos-easing="linear"
data-aos-duration={index * 400}>
    <Link to={`/case/${item?.url}`}>

<div className="img-efect"> 


<img src={item.img} />

</div>
<div className="label">
<h3>{item.titulo?.rendered}</h3>
<p><div dangerouslySetInnerHTML={ { __html : item.resumo?.rendered.slice(0,60)  }}/> </p>
</div>
</Link>
</Col>

)) : <>
<div className="d-flex">
<Spinner animation="border" role="status" className="m-auto">
      <span className="visually-hidden">Procurando postagens ...</span>
    </Spinner> 
</div>
</>}

</Row>




<div className="center" >
<Link className="link" to={'/cases'}  data-aos="zoom-in-up"
  data-aos-easing="linear">
      Ver todos nossos cases <i class="fa-solid fa-arrow-right"></i>
</Link>
</div>
</div>
</div>
</div>
    </>
  );
}
