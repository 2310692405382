import { Link, RouterProvider, useLocation } from 'react-router-dom';
import routes from './rotas/routes';
import { useEffect, useState } from 'react';
import './assets/reset.css'
import './assets/elcom.sass'
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import CookieConsent, { Cookies,getCookieConsentValue  } from "react-cookie-consent";
import Spinner from 'react-bootstrap/Spinner';
import AOS from 'aos';
import "aos/dist/aos.css";

function App() {
  const [load, setLoad] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoad(false)
    }, 2000)
    AOS.init();
    AOS.refresh();
  },[])
 
  const [sidebarIsOpen,setSidebarIsOpen] = useState(false);
  function toggle(value) {
    setSidebarIsOpen(value)
  }

  return (
    <div className="App">
  
       {load ? <>
          <div class="load" >
            <div className='d-flex ' style={{height: '100vh'}}>
         <div className='d-flex flex-column m-auto '>
         <img src="logo.png" width={'180px'} class="logo m-auto" />
         <div className='mx-auto mt-5'>
                <Spinner className='m-1' animation="grow" variant="primary"  size="sm" />
              <Spinner className='m-1' animation="grow" variant="primary" size="sm"  />
              <Spinner className='m-1' animation="grow" variant="dark"  size="sm" />

                </div>
         
         </div>

            </div>
          </div>
        </>
          : <>


<RouterProvider router={routes} />


          </>
        }
    </div>
  );
}

export default App;
