import React,{ memo, useEffect, useState } from "react";
import { Alert, Col, Pagination, Row, Spinner } from "react-bootstrap";
import { Pagina } from '../../template/pagina/pagina';
import './cases.sass'
import wp from "../../helpers/wp";
import { Header } from "../../template/header/header";
import { parseISO } from "date-fns";
import { formatToTimeZone } from "date-fns-timezone";
import { Link } from "react-router-dom";
export default memo(function CasesPagina({}) {
  const [noticias, setNoticias] = useState([]);
  useEffect(() => {
    wp.get("posts?_embed").then(res => {
      var postagens = [];
      res.data.find(item => {
        console.log(item)
        var parsedDate = parseISO(item.date);
        const output = formatToTimeZone(parsedDate, 'DD/MM/YYYY HH:mm', { timeZone: 'America/Sao_Paulo' })
        const tratamento = {
          data: output,
          titulo: item.title,
          tipo: item.tags,
          url: item.slug,
          setor: item.categories,
          resumo: item.excerpt,
          img: item.featured_media_src_url
        }
        postagens.push(tratamento)
      })
      setNoticias(postagens)
    }
      
      
      )

  },[])

  
return(<>
<>

<Header subtitulo={"CASES"} icon={"fa-solid fa-comments"} img={'./fale-conosco.jpg'}></Header>
<div className="cases">

<div className='fullscreen'>
<div className="container">
<p className="paragraph">
Confira aqui projetos especiais feitos para uma audiência qualificada e exibidos em pontos estratégicos espalhados por todo o país.
</p>
<Row sm="3" className="g-5 justify-content-md-center itens" >

{noticias.length > 0 ? noticias.map((item, index) => (

  <Col 
  className="item"
  sm data-aos="zoom-in-up"
  data-aos-easing="linear"
  data-aos-duration={index * 400}>
      <Link to={`/case/${item?.url}`}>

  <div className="img-efect"> 


<img src={item.img} />

</div>
<div className="label">
  <h3>{item.titulo?.rendered}</h3>
  <p><div dangerouslySetInnerHTML={ { __html : item.resumo?.rendered.slice(0,60)  }}/> </p>
</div>
</Link>
  </Col>

)) : <>
<div className="d-flex">
<Spinner animation="border" role="status" className="m-auto">
      <span className="visually-hidden">Procurando postagens ...</span>
    </Spinner> 
</div>
</>}



</Row>


<div className="center" >





</div>
</div>
</div>
</div>
</>
</>);

});