import { useEffect, useRef } from 'react';
import './header.sass'
export function Header({titulo,img,icon,subtitulo,video}) {

    const videoRef = useRef();
    useEffect(() => {
        setTimeout(()=>{
            videoRef?.current?.play()
        },5000)
    }, []);

    return (<>
        <div className='header'>
        {video && <div className='video'>
<video
            ref={videoRef}
            loop
            muted
          >
          <source src={video} type="video/mp4"/>       
       </video>
</div>}

{img && <div className='img'>
<img src={img} />
</div>}
            <div className="header-bar" style={{background: `url(${img})`}}>
             
                <div className="container" >
             <div className='my-auto texto '>
             <p>
             <i class={icon}></i>  {subtitulo}
                    </p>
             <h1 className="header">
                       {titulo}
                    </h1>
                  </div>
    
                </div>
            </div> 
   
        </div>

    </>)
}