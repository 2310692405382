import React,{ memo } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Pagina } from '../../template/pagina/pagina';
import './produtos.sass'
import { Header } from "../../template/header/header";
export default memo(function ProdutosPagina({}) {

return(<>
<>
<>
<Header titulo={"Sua marca merece estar num lugar de destaque!"} video={'./elpubli.mp4'} subtitulo={"Nossos produtos"} icon={"fa-solid fa-comments"} ></Header>


<div className="produtos">
<div className="container">
<h2 className="title">
QUALIDADE TÉCNICA
</h2>
<p className="paragraph">
O Painel Digital Full, que traz a tecnologia utiliza LED’s P10 SMD, dip alumínio ip68 blindado à prova d`água.
</p>
<Row sm="3" className="g-5 justify-content-md-center" >
  
        <Col sm>
        <Card bg="light" text="dark" className="p-4" >
        <Card.Img variant="top" src="./produtos/tela 1.webp" />
        <Card.Body>
          
          <Card.Title> SUA MARCA EXPOSTA EM UM CARTÃO POSTAL​</Card.Title>
          <Card.Text>
          O maior painel de LED do Brasil,
construído sobre um cartão postal, a ponte Rio-Niterói. Sem dúvida um dos maiores fluxos diário de veículos
do Brasil.
          </Card.Text>
        </Card.Body>

      </Card>
      </Col>
      <Col sm>
      <Card bg="light" text="dark" className="p-4" >
      <Card.Img variant="top" src="./produtos/tela 3.webp" />
        <Card.Body>
          <Card.Title>  VISIBILIDADE DO SEU ANÚNCIO</Card.Title>
          <Card.Text>
          150 mil veículos/dia.</Card.Text>
        </Card.Body>

      </Card>
      </Col>
      <Col sm>
      <Card bg="light" text="dark" className="p-4" >
      <Card.Img variant="top" src="./produtos/tela 6.webp" />


        <Card.Body>
          <Card.Title>  VOLUME DE INSERÇÕES

</Card.Title>
          <Card.Text>
          Seus anúncios veiculados quase 20 mil vezes por mês.
          </Card.Text>
        </Card.Body>

      </Card>
      </Col>

      <Col sm>
      <Card bg="light" text="dark" className="p-4" >
      <Card.Img variant="top" src="./produtos/tela 7.webp" />
        <Card.Body>
          <Card.Title>PROGRAMAÇÃO</Card.Title>
          <Card.Text>
          Grade com 10 COTAS;

Cada anúncio com 10 segundos;

Seu anúncio veiculado a cada 2

minutos.
          </Card.Text>
        </Card.Body>

      </Card>
      </Col>

      <Col sm>
      <Card bg="light" text="dark" className="p-4" >
      <Card.Img variant="top" src="./produtos/tela 8.webp" />

        <Card.Body>
          <Card.Title>DADOS TÉCNICOS DE EXIBIÇÃO

</Card.Title>
          <Card.Text>
   <strong>
   Como produzir o anúncio:

Formato: 3936 x 672 pixel

Resolução da imagem: 300pixel   

Arquivo: JPG / PNG Máximo 800mb

Obs:
   </strong>
   <p>
   Veiculação do anúncio SEM ÁUDIO. Imprescindível o uso de legendas.

Evite fundo branco.

Evite fontes finas e pequenas.
   </p>
          </Card.Text>
        </Card.Body>

      </Card>
      </Col>


  

    </Row>

</div>
</div>

</>
</>
</>);

});