import * as React from 'react';
import './rodape.sass'
import { Link } from 'react-router-dom';
export function Rodape() {
   
    return (<> 
<footer>
  <div className='container'>
  <div className="row">
  <div className="col-sm-5 ">
    <div className='d-flex'>
    <img className='logo m-auto' src='logo.png'  alt="logo Elcom Rodape" height={120} ></img>
     <p style={{fontSize: '.9rem'}} className='m-auto p-4' >
     ELCOM comunicação é uma empresa de mídia pioneira que está mudando a face da indústria Out of Home no Brasil.

</p>
    </div>


     
    </div>
    <div className="col-sm px-4">

<h4>Acesso rapido</h4>
<ul class="menu-rodape">
    <li class="list-group-item"><Link to={'/sobre'}>Sobre nós</Link></li>
    <li class="list-group-item"><Link to={'/produtos'}>Produtos</Link> </li>
    <li class="list-group-item"><Link to={'/cases'}>Cases</Link> </li>

</ul>
    </div>
    <div className="col-sm">
  <a className="btn whatsapp">
  <p className='icon'>
<i className="fa-brands fa-whatsapp"></i>
  <span>
  (21) 97304-1311
  </span>
</p>
  </a>
{/* <p className='info'>
  Central de Relacionamento
Segunda a sexta, das 8h às 20h
e sábados das 8h às 14h (exceto feriados)</p> */}
{/* <p className='icon'>
<i class="fa-solid fa-phone"></i>
  <span>
  <p className='reset'>
  Marielson Xavier
<br></br>
CEO Grupo ELCOM
  </p>
  Tel.: 55+21- 97304-1311
  </span>
</p>


<p className='icon'>
<i class="fa-brands fa-whatsapp"></i>
  <span>
  (21) 99976-5338
  </span>
</p>

<a className='email'>
<p className='icon'>
<i class="fa-regular fa-envelope"></i>
  <span>
  atendimento@grupoelcom.com.br
  </span>
</p>
</a>

<a className='email'>
<p className='icon'>
<i class="fa-regular fa-envelope"></i>
  <span>
  xavier.ceo@grupoelcom.com.br
  </span>
</p>
</a> */}



    </div>
  </div>
  </div>
  <div className='bar container'>
  <div className="row">
    <div className='col-sm d-flex flex-row p-3 links'>
    <a>Política de Privacidade</a>
    <a>Proteção de Dados</a>
    <a>Política de Cookies</a>
    </div>
    <div className='col-sm d-flex redes py-3'>
    {/* <a>
    <i class="fa-brands fa-twitter"></i>
        </a> */}
        <a href='https://www.facebook.com/grupoelcom/' target="_blank">
        <i class="fa-brands fa-facebook-f"></i>
        </a>
        <a href='https://www.instagram.com/grupoelcom/'> 
        <i class="fa-brands fa-linkedin-in"></i>
        </a>
        <a href='https://www.instagram.com/grupoelcom/' target="_blank">
        <i class="fa-brands fa-instagram"></i>
        </a>

    </div>
  </div>
  </div>
  <div className='copyright'>
    <div className='container'>
    El Comunicacao Visual Ltda  © 2023 - Todos os direitos reservados CNPJ: 21.365.658/0001-77


    </div>
</div>
</footer>
<a href="https://wa.me/5521973041311" className="whatsapp-float" target="_blank"> <i className="fa-brands fa-whatsapp"></i></a>
    </>)
}