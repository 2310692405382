import { Link, Outlet } from 'react-router-dom';
import { Rodape } from '../rodape/rodape';
import { Topo } from '../topo/topo';
import CookieConsent from 'react-cookie-consent'
export function Pagina({titulo, children,escuro}) {
    return (<>
    <Topo escuro={escuro}/>
        <Outlet />
        <Rodape/>

    </>)
}