import React from 'react'
import { createBrowserRouter } from 'react-router-dom';
import SobrePagina from '../paginas/sobre';
import PaginaPrincipal from '../paginas/principal/index'
import ContatoPagina from '../paginas/contato/index'
import ProdutosPagina from '../paginas/produtos/index'
import CasesPagina from '../paginas/cases'
import { Pagina } from '../template/pagina/pagina';
import CasesPaginaCustom from '../paginas/cases/pagina';
 const routes = createBrowserRouter([
    { 
    path: '/',
    element: <Pagina/>,
    children: [
        { index: true, element: <PaginaPrincipal /> },
 {
                 index: true,
                path: 'sobre',
                element: <SobrePagina />
            },

            {
                index: true,
                path: 'cases',
                element: <CasesPagina />
            },
            {
                index: true,
                path: 'servicos',
                element: <ProdutosPagina />
            },
            {
                index: true,
                path: 'fale-conosco',
                element: <ContatoPagina />

            },

            {
                path: 'case/:slug',
                element: <CasesPaginaCustom />
            },

      
          { path: "*", element: <>teste</>},

      ]
    }
])
export default routes;