import { Link } from "react-router-dom";
import  './clientes.sass'
import { Card } from "react-bootstrap";
export function Clientes() {
  const itens = [
  {
    titulo: 'Drogarias Pacheco',
    img: './empresas/pacheco.png'
  },
  {
    titulo: 'Mercado Livre',
    img: './empresas/ml.png'
  },
  {
    titulo: 'Itaú',
    img: './empresas/itau.png'
  },
  {
    titulo: 'oBoticário',
    img: './empresas/boticario.png'
  },
  {
    titulo: 'Toyota',
    img: './empresas/toyota.png'
  },
  {
    titulo: 'Tim',
    img: './empresas/Tim.png'
  },
  {
    titulo: 'Globo',
    img: './empresas/globo.svg'
  },
  {
    titulo: 'Casa e video',
    img: './empresas/casaevideo.png'
  },
  {
    titulo: 'Nissan',
    img: './empresas/nissan.png'
  },


]
  return (
    <>
     <div className="clientes">

<div className='fullscreen' id="clientes">
<div className="container">
<h3 className="title">Alguns dos nossos clientes
</h3>

<div className="row row-cols-sm-6 p-4 gap-4 justify-content-md-center"> 
{itens.map((item, index) => (
  <div className="col-sm"  data-aos="zoom-in-up"
  data-aos-easing="linear"
  data-aos-duration={index * 400}>
<Card className="d-flex">
<img src={item.img} alt={item.titulo} className="my-auto" />
</Card>


  </div>

))}





</div>


</div>
</div>
</div>
    </>
  );
}
