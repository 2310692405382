import React,{ memo, useState } from "react";
import { Alert, Button, Card, Col, Container, FloatingLabel, Row } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import { Pagina } from '../../template/pagina/pagina';
import GoogleMapReact from 'google-map-react';
import { Header } from "../../template/header/header";
import './contato.sass'
import { useForm } from 'react-hook-form';
import wpcontato from "../../helpers/wp";
import axios from "axios";

export default memo(function ContatoPagina() {
  const [msg, setMsg] = useState("")
  const [tipo, setTipo] = useState("")
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

    const enviarForm = (data) => {

  const formBody = new FormData();
  formBody.append('nome',data.nome )
  formBody.append('email',data.email )
  formBody.append('assunto',data.assunto )
  formBody.append('telefone',data.telefone )
  formBody.append('empresa',data.empresa )
  formBody.append('msg',data.msg )
  formBody.append('aceite',data.aceite )
  axios({
    method: 'post',
    url: 'https://grupoelcom.com.br/wp-json/contact-form-7/v1/contact-forms/4/feedback',
    data: formBody
})
.then(function (response) {
    if(response.status = 200)  {
      setMsg(response.data.message)
      setTipo('success')
      reset()
      } else {
        setMsg(response.data.message)
        setTipo('danger')
    }
})

    } 



    
return(<>
< >
<>
<Header titulo={"E COM O SEU PÚBLICO"} subtitulo={"FALE CONOSCO"} icon={"fa-solid fa-comments"} img={'./faleconosco.jpg'}></Header>
<Container className="contato ">
<Row className="reset">
<Col sm={6} className="flex reset">
<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d22028.505883112128!2d-43.1266027096595!3d-22.89248818729279!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9983c164f80ec1%3A0xbb9111b28a817b4b!2sR.%20S%C3%A3o%20Pedro%2C%20154%20-%20Centro%2C%20Niter%C3%B3i%20-%20RJ%2C%2024020-058!5e0!3m2!1spt-BR!2sbr!4v1681074464704!5m2!1spt-BR!2sbr" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
</Col>
<Col  sm={6} className="p-5" >
<Form onSubmit={handleSubmit(enviarForm)}>
{msg.length > 0 && 
<Alert key={tipo} variant={tipo}>
          {msg}
        </Alert>
}

    <Row >

  
    <Col sm>

<FloatingLabel
controlId="nome"
label="Nome"
className="mb-3"
>
<Form.Control type="text" placeholder="Nome"              required
 {...register('nome', { required: true })}/>

</FloatingLabel>
</Col>

<Col sm>


<FloatingLabel controlId="floatingInput" label=" E-mail"
>
<Form.Control type="email" placeholder="seuemaiL@email.com"                 required
   {...register('email', { required: true })}/>
</FloatingLabel>
</Col>

</Row>


<Row>

  
<Col sm>

<FloatingLabel
controlId="telefone"
label="Telefone"
className="mb-3"
>
<Form.Control type="text"             required
 placeholder="Telefone" {...register('telefone', { required: true })}/>
</FloatingLabel>
</Col>

<Col sm>


<FloatingLabel controlId="floatingInput" label="Empresa"
>
<Form.Control type="empresa"             required
 placeholder="Empresa" {...register('empresa', { required: true })}/>
</FloatingLabel>
</Col>

</Row>



<Row>

  
<Col sm>

<FloatingLabel
controlId="Mensagem"
label="Mensagem"
className="mb-3"
>
<Form.Control type="textarea" placeholder="Mensagem"             required
style={{ height: '100px' }} {...register('msg', { required: true })}/>
</FloatingLabel>
</Col>



</Row>
<Row>
  <Col>
  <Form.Group className="mb-3" controlId="formBasicCheckbox">
        <Form.Check type="checkbox" label=" Aceito receber e-mails com informações relevantes sobre Elcom."  {...register('aceito', { required: true })} />
      </Form.Group>
      </Col>
</Row>
<Row>

  
<Col sm className="d-flex gap-2 mt-2">

<Button variant="primary" type="submit">
        Enviar
      </Button>

      
<Button variant="secondary" type="reset">
        Limpar
      </Button>
      </Col>



</Row>
</Form>

</Col>
</Row>
  
    
    
</Container>

</>
</>
</>);

});