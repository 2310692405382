import { useContext, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "./bemvindo.sass";
const charPoses = {
  exit: { opacity: 0, x: 20 },
  enter: {
    opacity: 1,
    x: 0
  }
};

export function BemVindo() {
  const videoRef = useRef();
  useEffect(() => {
      setTimeout(()=>{
          videoRef?.current?.play()
      },5000)
  }, []);
  return (
    <>
      <div   className="bemvindo">
      <div className='video'>
<video
            ref={videoRef}
            loop
            muted
          >
          <source src="videohome.mp4" type="video/mp4"/>       
       </video>
</div>


        <div className="pt-5 container">
    <div className="row">
      <div className="col-sm-12 flex" data-aos="fade-down"
          data-aos-duration="1000">
            <div className="my-auto">
            <h1>
           <>
           O sucesso<br></br> da sua empresa<br></br> começa aqui!
           </>
       
            </h1>

            </div>
       
         
          </div>
          {/* <div className="col-sm-5 flex order-mobile">
      <div className="img ms-auto flex ">
          </div>
      </div> */}
    </div>
          
        </div>
      </div>
    </>
  );
}
