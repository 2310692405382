import React, { memo } from 'react';
import { Pagina } from '../../template/pagina/pagina';
import {BemVindo} from './bemvindo'
import { Sobre } from './sobre';
import { Newslatter } from './newslatter';
import { Cases } from './cases';
import { Clientes } from './clientes';
export default memo(function PaginaPrincipal() {
    return <>
<>
<BemVindo/>
<Sobre/>
<Newslatter/>
<Cases/>
<Clientes/>
</>
    </>;
  });
  