import { Link } from "react-router-dom";
import "./sobre.sass";
export function Sobre() {
  return (
    <>
      <div className="sobre">
        <div className="container">
        <div className="row">
        <div className="col-sm-6 d-flex ">

<div className="m-auto texto">
  <div className="texto-left" >
  <h3>Sobre o </h3>
  <h4>Elcom</h4>
  </div>

<p data-aos="zoom-in-up"
  data-aos-easing="linear"
  data-aos-duration={200}>  
Comunicação eficaz é fundamental para o sucesso de uma empresa, independentemente do tamanho. Mas às vezes, atrair a atenção dos consumidores e transmitir sua mensagem de forma clara pode ser um desafio. É aí que entra o Grupo elcom, uma empresa nacional especializada em criar e implantar projetos de comunicação visual e mobiliários urbanos em escala gigantesca.
</p>
<p data-aos="zoom-in-up"
  data-aos-easing="linear"
  data-aos-duration={300}>
Com a sua expertise, o Grupo elcom adiciona beleza aos centros urbanos e transforma cenários, criando novos pontos de atração turística. A comunicação eficaz e a beleza urbana se unem para oferecer uma experiência única aos consumidores e destacar sua marca de uma forma nunca antes vista.

</p>
<p data-aos="zoom-in-up"
  data-aos-easing="linear"
  data-aos-duration={400}>
Não perca mais tempo e oportunidades de crescimento por causa de uma má comunicação. Deixe o Grupo elcom ajudá-lo a transmitir sua mensagem com impacto e atrair a atenção dos consumidores. Juntos, podemos transformar a sua empresa em um verdadeiro destaque no mercado.</p>

<div data-aos="zoom-in-up"
  data-aos-easing="linear"
  data-aos-duration={500}>
<Link className="link" to={'/sobre'}>
      Ver institucional <i class="fa-solid fa-arrow-right"></i>
</Link>
</div>
</div>

</div>
        <div className="col-sm-6 order-mobile" style={{position: 'relative'}}  data-aos="zoom-in-up"
  data-aos-easing="linear"
  data-aos-duration={200} >
        <div className="efeito  "  alt="Mulher segurando cartão IBi Pay Azul" style={{backgroundImage: `url('./grupoelcom.png')`}}>
   
        </div>

        </div>
    
        </div>
        </div>
   

      
      </div>
    </>
  );
}
